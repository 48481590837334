@import "@fontsource/roboto-slab"; 
@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@800&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* 
  Header styles
  background-color: #001333;
*/
.navbar  {
  background-color: #001333;
  color: white;
  font-family: 'Roboto Slab', serif;
  z-index: 999;

}
.navbar-toggler{
  border: none; 
  background-color: #001333;
}


.collapse {
  float: right;

}

.navbar-brand   {
  color: white;
  font-family: 'Spectral', serif;
  font-size: 22px;
  display: inline-block;

  


}

.nav-item a {
  font-family: 'Spectral', serif;
  font-size: 18px;
  color: white;
  

  text-decoration: none;
  float: right;

}



.jumbotron{
  background-color: #001333;
  color: rgb(255, 89, 90);
  font-family:  'Spectral', serif;
  font-size: 30px;
  
  
  
}

.c {
  border: none;
}

.jumbotron p {
  font-size: 20px;
  font-family: 'Spectral', serif;
}



.c {
  background-color: transparent
}
.card-body {
border : none;


}

#how {
  
  font-size: 30px;

  display: flex;
  background-color: white;
}
#howcardbody{
  color: white;
}
 .card {
  background-color: #001333;
}


#Sub {
  font-size: 22px;
  text-align: center;
  font-family: 'Spectral', serif;

  color: white;
  display: flex
 
}


#caro{
  background-color: white;

  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  padding-top: 5px;


}
#caro h1{
  font-size: 55px;
  font-family: 'Spectral', serif;
  color: black;
  font-weight: bold;
}

#caro h4 {
  font-size: 25px;
  font-family: 'Spectral', serif;
  color: black;
  font-weight: bold;
}

#About {
  background-color: #001333;
  margin-bottom: 0;
}

#About h2 {
  font-size: 55px;
  font-family: 'Spectral', serif;
  font-weight: bold;
  color: rgb(255, 89, 90);
}

#About p {
  font-size: 20px;
  font-family: 'Spectral', serif;
  color: white
}


.footer {
  background-color: #001333;
  color: white;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;

  bottom: 0;
  width: 100%;
  z-index: 999;
}

