#aboutus {
    color: white;
}

#aboutintro {
    padding-top: 50px;
}

#aboutid {
    padding-top: 30px;
}

#aboutcontent p {
    font-size: 20px;
    font-family: 'Roboto Slab', sans-serif;
    
    
}

#aboutcontent h1 {
    font-size: 30px;
    font-family: 'Roboto Slab', sans-serif;
    font-weight: bold;
}

#aboutcontent {
    padding-bottom: 30px;
}

@media only screen and (max-width: 414px) {
    #aboutcontent {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #aboutcontent h1 {
        font-size: 30px;
    }

    #aboutcontent p {
        font-size: 17px;
    }

    #aboutintro p {
        font-size: 17px;
    }
    #aboutintro {
        padding-top: 14px;
    }
}

#culturecontent {
    padding-top: 35px;
}