.dashboard {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .dashboard__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .dashboard__btn {
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .dashboard div {
    margin-top: 7px;
  }


#dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: center;
    height: 45vh;
    width: 100vw;
    
}


.navbar-brand {
  color: white; 
}

.nav-item {
  text-transform: lowercase;
}

h6 {
    font-family: 'Spectral', serif;
    font-size: 50px;
}

#cards {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;

}
/*
@media only screen and (max-width: 414px) {
    #cards {
        flex-direction: column;
        justify-content: center;
    }
}*/
#gold {
    background-color: #006aff;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
}
/*
#grey {
    background-color: #dcdcdc;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
}
*/

.card {
    height: 20px;
    width: 300px;
}

.card-title {
    font-family: 'Spectral', serif;
    font-size: 28px;
    color: #ffffff;

}

.d {
  padding-bottom: 50px;
}

#titles p {
  font-family: 'Spectral', serif;
  font-size: 22px;
  color: black
}

#help {
  padding-top: 80px;
  
}

#help h2 {
  font-size: 30px;
  font-family: 'Roboto Slab', sans-serif;
}


@media screen and (max-width: 576px) {
  
  h6 {
    font-size: 35px;
  }

  #help {
    padding-top:50px;
  }
  #help h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 380px) {
  h6 {
    font-size: 30px;
  }
  #help {
    padding-top: 40px;
  }
  #help h2 {
    font-size: 20px;
  }
}