/* make categories list scrollable */
#Categories {
    overflow-x: scroll;
    height: 100%;
}


#v {
    font-size: 19px;
    font-family: 'Roboto Slab', sans-serif;

}

.categoriesCard {
    background-color: white;
    border: 2px solid #ddd;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    text-align: center;
    overflow-x: scroll;
    overflow-y: scroll;
    min-width: 300px;
    max-width: 300px;
}

#categories-container {
    margin-bottom: 30px;
    overflow-y: scroll;
    padding-top: 15px;
}

@media only screen and (max-width: 414px) {
    #categories-container {
        padding-top: 0px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .categoriesCard h2 {
        font-size: 20px;
         
    }

    .categoriesCard {
        min-width: 150px;
        max-width: 150px;
    }
}

#ccontainer {
    margin-bottom: 50px;
}
