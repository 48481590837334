.restaurant-cards {
    display: inline-flex;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 10px;
    font-family: 'Roboto Slab', sans-serif;
    
  }
  
  .restaurantCard {
    background-color: white;
    font-family: 'Roboto Slab', sans-serif;
    border: 2px solid #ddd;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    text-align: center;
    overflow-x: scroll;
    overflow-y: scroll;
    min-width: 300px;
    max-width: 300px;

  }

  @media screen and (max-width: 414px) {
    .restaurantCard{
        min-width: 200px;
        max-width: 200px;
        height: 440px;
        
        
        
       
    }
    .restaurantCard p {
        font-size: 14px;
    }
    .restaurantCard h7 {
        font-size: 16px;
    }

    .restaurantCard h2{ 
        font-size:20px;

    }

    .restaurantCard button {
        font-size:12px;
    }

    .restaurantCard img {
        height: 140px;
    }

    #head h1{
        font-size: 35px;
        font-family: 'Roboto Slab', sans-serif;
    }

    #restaurants-container.container{
        padding-top: 0px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
 
}

@media screen and (max-width: 575px) {
    #head h1{
        font-size: 35px;
    }

}

 
#restaurants {
    font-family: 'Roboto Slab', sans-serif;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: scroll;
}

#restaurants-container{
    font-family: 'Roboto Slab', sans-serif;
    margin-bottom: 30px;
    overflow-y: scroll;
    
}

#head {
    display: flex;
    flex-direction: row;

}

#head h1{
    font-family: 'Roboto Slab', sans-serif;
    
}

#buttonz{
    text-align:center;
}


  
