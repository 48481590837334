.navbar-brand h3{
    color: white;
    font-family: 'Spectral', serif;
    font-size: 24px;
    display: inline-block;
    margin: 0;
    
}

.nav-itm h3 {
    text-transform: lowercase;
    font-family: 'Spectral', serif;
    font-size: 18px;
    color: white;
    margin: 0;
  
    text-decoration: none;
    float: right;
}

.navbar-brand {
    display: inline-block;
    padding-left: 100px;
}

#left {
    padding-right: 100px;
  }

.tog{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  
  }

  @media only screen and (max-width: 768px) {
    .navbar-brand {
        padding-left: 20px;
    }

    #left {
        padding-right: 0px;
    }
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

}



