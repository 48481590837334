#Intro {
   margin-top: 24px;
}

#HowIt h2 {
    color : black;
    font-size: 50px; 
   
}

#Intro h2 {
    color: white; 
    font-size: 50px;
    
}

.steps {
    background-color: none;
}

#Howheader h2 {
    color: white; 
}

#Howjumbo {
    background-color: white;
    padding-bottom: 0px;
}

#Howjumbo h1 {
    color: black;
}
.stp {
    
    padding-top: 30px;
    
}

#Intro h4 {
    color: white;
}

#Intro h3 {
    color: black;
}

#Intro h1 {
    color: white;
}

.stp-text a {
    font-size: 20px;
    
    /* center the text */
    text-align: center;
    
}

.Hows {
    padding-top: 70px;
}

#HowMedia {
    height: 170px;
}

@media only screen and (max-width: 450px) {
    #Intro h2 {
        font-size: 40px;
    }

    #Intro h4 {
        font-size: 20px;
    }

   #Intro h3 {
        font-size: 20px;
    }

     .step {
        min-width: 250px;
        max-width: 250px;
        
    }

    #HowMedia {
        height :100px; 
    }
    #Intro {
        padding-bottom: 50px;
    }
}
