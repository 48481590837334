#diningcard {
    padding: 40px;
}

#diningcard h2 {
    color: #001333;
    font-size: 30px;
    font-family: 'Roboto Slab', sans-serif;
}

#qr-container h1{
    font-family: 'Roboto Slab', sans-serif;
}

#smartphone {
    text-align: center;
    font-family: 'Roboto Slab', sans-serif;
    font-size: 30px;
    font-weight: bold;
    justify-content: center;
    align-items: center;

    padding-bottom: 40px;
}

#qr-container{
    padding: 50px;

}

@media only screen and (min-width: 550px) and (max-width: 1199px) {
    #qr-container{
        padding: 20px;
    }

    #diningcard h2 {
        font-size: 20px;
    }
    #cardtitle {
        padding-bottom: 30px;
    }
} 

@media only screen and (min-width: 350px) and (max-width: 550px) {
    #qr-container{
        padding: 20px;
    }

    #diningcard h2 {
        font-size: 17px;
    }
    #cardtitle {
        padding-bottom: 30px;
    }
    
}

@media only screen and (max-width: 350px) {
    #qr-container{
        padding: 20px;
    }

    #diningcard h2 {
        font-size: 15px;
    }
    #cardtitle {
        padding-bottom: 30px;
    }

    #userid {
        display: none;
    }
    
    
}


