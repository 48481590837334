#welcome{
    text-align: center;
    font-family: 'Spectral', serif;
    font-size: 35px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    
  }
  #buy {
    
    font-family: 'Spectral', serif;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    padding: 100px;
    
  }

  #buy h2 {
    font-size: 55px;
    color: rgb(255, 89, 90);
    font-family: 'Spectral', serif;
  }
  
  #buy p{
    font-size: 20px;
    font-family: 'Spectral', serif;
    color: white;
  }

  .card-image {
    width: 400px;
  }

  @media only screen and (max-width: 390px) {
    #buy {
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 20px; 
    }

    #buy h2 {
        font-size: 30px;
    }

    #welcome h1{
        font-size: 27px;
    }

    
  }

  @media only screen and (min-width: 390px ) and (max-width: 450px) {
    #buy {
        padding-top: 25px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 45px; 
    }

    #buy h2 {
        font-size: 45px;
    }

    #welcome h1{
        font-size: 32px;
    }
    
  }



  @media only screen and (max-width: 1100px) {
    .img-fluid img {
        display: none;
    }
  }


