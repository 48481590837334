#est_savings { 
    font-size: 125px;
    color: rgb(255, 89, 90); 
    
    
}

#savings {
    padding-top: 20px;
    
}

#bottom_savings {
    padding-top: 20px;
}

#num_trips {
    color: #001333;
    font-size: 30px;


    text-align: center;

}

#trips_cards {
    border: 0.5px solid #ddd;
    height: 65px;
    box-shadow: none;

}

#trip_card {
    padding-top: 10px;
}

#empty {
    justify-content: center;
    text-align: center;
    padding: 20px;
    border:  0.5px solid #ddd;
}

#savings h5 {
    font-size: 19px;
}

#account_savings {

    text-align: center;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    #recenttrips {
        padding-top: 35px;

    }

    #trips_cards {
        justify-content: center;
        
    }

    #cnt_cards {
        justify-content: center;
    }
}

@media screen and (max-width: 767px)  {
    #recenttrips {
        padding-top: 35px;

    }

    #trips_cards {
        justify-content: center;
        
    }

    #cnt_cards {
        justify-content: center;
    }
}

@media screen and (max-width: 575px)  {
    #account_savings {
        text-align: center;
    }

    #savingsname {
        display: none;
    }

    #num_trips {
        text-align: center;
        font-size: 90px;
    }

    #cnt_cards {
        justify-content: center;
        font-size: 15px;
    }
    #est_savings {
        font-size: 90px;
        text-align: center;
    }

    #est_savingstitle {
        text-align: center;
        padding-top: 15px;
    }

    #recenttrips {
        padding-top: 15px;
        

    }
}

@media screen and (max-width: 414px) {
    #est_savings {
        font-size: 80px;
    }

    #savings {
        padding-top: 10px;
    }

    #bottom_savings {
        padding-top: 10px;
    }

    


    #empty {
        padding: 10px;
    }

    #savings h5 {
        font-size: 23px;
    }

    #account_savings {
        padding-top: 10px;
    }

    #num_trips {
        font-size: 80px;
    }
}

@media screen and (max-width: 359px) {
    #est_savings {
        font-size: 60px;
    }
    #savings h5 {
        font-size: 16px;
    }
    #cnt_cards {
        justify-content: center;
        font-size: 12px;

        
    }

    #trips_cards {
        border: 0.5px solid #ddd;
        height: 80px;
        box-shadow: none;
       
    
    }

    #num_trips {
        font-size: 60px;
    }
  }


