#Rulesheader h3{
    color: white; 
    font-family: 'Spectral', serif;
    font-size: 50px;
}

.Rule {
    background-color: none;
    padding-left: 100px;
    padding-right: 100px;
    font-family: 'Spectral', serif;
    font-weight:lighter;
}


ul {
    list-style-type: none;
    font-size: 20px;
}