.restaurant-cards {
    display: inline-flex;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 10px;
  }
  
  .restaurantCard {
    background-color: white;
  
    border: 2px solid #ddd;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    text-align: center;
    overflow-x: scroll;
    overflow-y: scroll;
  

  }
 
#restaurants {

    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: scroll;
    
}

#trips-container{

    margin-bottom: 50px;
    overflow-y: scroll;
    padding: 40px;
    
}

#head {
    display: flex;
    flex-direction: row;

}

#buttonz{
    text-align:center;
}
  