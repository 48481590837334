#Howheader h2 {
    color: white; 

}

#Howjumbo {
    background-color: white;
    padding-bottom: 0px;
    font-family: 'Roboto Slab', sans-serif;
}

#Howjumbo h1 {
    color: black;
    font-family: 'Roboto Slab', sans-serif;
}
.step {
    
    padding-top: 30px;
    font-family: 'Roboto Slab', sans-serif;
    
}

#Howtitle h4 {
    color: black;
}

.step-text a {
    font-size: 20px;
    
    /* center the text */
    text-align: center;
    

}
.help p {
    color: black;
    padding-top: 60px;
    padding-bottom: 20px;
    font-family: 'Roboto Slab', sans-serif;
}