/* accordion */
#questions {
  padding-top: 10px;
  padding-bottom: 35px;
  font-family: 'Roboto Slab', sans-serif;
}

#contact {
  padding-top: 50px;
  padding-bottom: 80px;
  font-family: 'Roboto Slab', sans-serif;
}

#help-container {
  padding-bottom: 30px;
  font-family: 'Roboto Slab', sans-serif;
}